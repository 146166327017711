import React from 'react';
import clsx from 'clsx';
import moment from 'moment';

import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '@material-ui/core/Tooltip';

import Button from '@common_button';

import { isEmpty } from '@common_tableseller/helpers';

import useStyles from '@common_tableseller/components/FilterApplied/style';

const FilterApplied = (props) => {
    const {
        search, setSearch, t, setExpandedToolbar, filters, setFilters, initialFilters,
    } = props;

    const classes = useStyles();
    const handleReset = () => {
        const resetedFilters = initialFilters.map((filter) => (
            { ...filter, value: filter.initialValue }
        ));
        setSearch('');
        setExpandedToolbar(false);
        setFilters(resetedFilters);
    };

    const renderFilterItem = () => {
        const res = [];
        filters.forEach((filter) => {
            if (filter?.children?.length) {
                filter.children.forEach((child) => res.push({
                    ...child,
                    label: `${filter.label} ${child.label}`,
                    parent_name: filter.name,
                }));
            } else {
                res.push(filter);
            }
        });
        return res;
    };

    const renderValue = (filt) => {
        let valueToRender = filt.value;
        if (typeof filt.value === 'object') {
            if (filt.options?.length) {
                if (filt.value?.length) {
                    // eslint-disable-next-line max-len
                    valueToRender = filt.value.map((val) => filt.options.find((opt) => opt[val.primaryKey || 'value'] === val[val.primaryKey || 'value'])?.[filt.labelKey || 'label'])?.join(', ');
                } else {
                    valueToRender = filt.options.find((opt) => opt.value === filt.value[filt.primaryKey || 'value'])?.[filt.labelKey || 'label'];
                }
            } else {
                // eslint-disable-next-line no-lonely-if
                if (filt.value?.length) {
                    // eslint-disable-next-line max-len
                    valueToRender = filt.value.map((val) => val[filt.labelKey || 'label'])?.join(', ');
                } else {
                    valueToRender = filt?.[filt.labelKey || 'label'];
                }
            }
        }
        if (filt.frontend_input === 'datetime') {
            return moment(valueToRender).format(filt.format);
        }
        return valueToRender;
    };

    const handleCloseFilterItem = (filter) => {
        const newFilters = [...filters];
        const index = newFilters.findIndex((f) => f.name === filter.name);
        if (index > -1) {
            newFilters[index].value = newFilters[index].frontend_input === 'select' ? null : '';
        } else if (filter.parent_name) {
            const parentIndex = newFilters.findIndex((f) => f.name === filter.parent_name);
            if (parentIndex > -1) {
                const indexChild = newFilters[parentIndex].children.findIndex((f) => f.name === filter.name);
                if (indexChild > -1) {
                    newFilters[parentIndex].children[indexChild].value = null;
                }
            }
        }
        setFilters(newFilters);
    };

    const expand = !!(search || !renderFilterItem().filter((filter) => !filter.hidden)
        .every((field) => isEmpty(field.value)));

    return (
        <div className={clsx(classes.expandContainer, expand && 'expand')}>
            <Collapse in={expand}>
                <div className={classes.expandGrid}>
                    {(!!search || !renderFilterItem().every((field) => isEmpty(field.value)))
                        && (
                            <Button
                                className={classes.btnFilterText}
                                onClick={handleReset}
                            >
                                {t('common:Reset_Filter')}
                            </Button>
                        )}
                    <Grid container spacing={2}>
                        {!!search
                            && (
                                <Grid item xs="auto">
                                    <div className={classes.filterItem}>
                                        <div className={classes.filterItemLabel}>{`${t('common:Keyword')}: ${search}`}</div>
                                        <IconButton className={classes.closeButton} onClick={() => setSearch('')}>
                                            <CloseIcon className={classes.closeIcon} />
                                        </IconButton>
                                    </div>
                                </Grid>
                            )}
                        {renderFilterItem()?.filter((filter) => !isEmpty(filter.value) && !filter.hidden)?.map((filter) => (
                            <Grid item xs="auto">
                                <Tooltip
                                    arrow
                                    title={`${filter.label}: ${renderValue(filter)}`}
                                    classes={{
                                        tooltip: classes.tooltip,
                                        arrow: classes.tooltipArrow,
                                    }}
                                    placement="top"
                                >
                                    <div className={classes.filterItem}>
                                        <div className={classes.filterItemLabel}>{`${filter.label}: ${renderValue(filter)}`}</div>
                                        <IconButton className={classes.closeButton} onClick={() => handleCloseFilterItem(filter)}>
                                            <CloseIcon className={classes.closeIcon} />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </Collapse>
        </div>
    );
};

export default FilterApplied;
