/* eslint-disable brace-style */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-confusing-arrow */
/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from '@i18n';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';

import ScheduleIcon from '@material-ui/icons/Schedule';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ImportExportIcon from '@material-ui/icons/ImportExport';

import { useColumns, getComponentOrString, useDebounce, isEmpty } from '@sellermodules/order/pages/list/components/ListCard/helpers';
import Button from '@common_button';
import formatDate, { getMinDate } from '@helper_date';

import TablePaginationActions from '@sellermodules/order/pages/list/components/ListCard/components/TablePaginationActions';
import TabsHeader from '@sellermodules/order/pages/list/components/ListCard/components/TabsHeader';

import useStyles from '@sellermodules/order/pages/list/components/ListCard/style';

const CustomTable = (props) => {
    const { t } = useTranslation('common');
    const {
        showCheckbox = false,
        rows,
        rowActions,
        actions,
        getRows,
        loading,
        filters: initialFilters = [],
        initialPage = 1,
        initialRowsPerPage = 50,
        count,
        hideFooter = false,
        handleClickRow = null,
        indexType = 0,
        getVariables,
        doRefetch = null,
        setDoRefetch = () => { },
        handleChecked = () => { },
        defaultChecked = [],
        singleSelection = false,
        useTabs = false,
        onReloadAllProductCount = () => { },
        rangeDate = 3,
        rowsSyncFailed,
        countSyncFailed,
        handleRetry,
        setSelectedPrintLabel,
        setOpenConfirmation,
        isMultichannel,
        allowedMpPrintLabel,
        aclEdit,
    } = props;
    // hooks
    const router = useRouter();
    const classes = useStyles();

    const [page, setPage] = useState(initialPage);
    const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);
    const [checkedRows, setCheckedRows] = useState(defaultChecked);
    const [isCheckedAllRows, setIsCheckedAllRows] = useState(false);
    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    const [selectedDate, setSelectedDate] = React.useState([formatDate(getMinDate(rangeDate), 'YYYY-MM-DD 00:00:00'),
        formatDate(new Date(), 'YYYY-MM-DD 23:59:59')]);
    const { columns } = useColumns(props.columns);
    const { columns: columnsSyncFailed } = useColumns(props.columnsSyncFailed);
    const [sorts, setSorts] = useState(
        columns.filter((column) => column.sortable).map(({ field, initialSort }) => ({ field, value: initialSort || undefined })),
    );

    const [filters, setFilters] = useState([...initialFilters.map((filter) => ({ ...filter, value: filter.initialValue })),
        {
            field: 'order_date',
            name: 'order_date_from',
            type: 'from',
            value: selectedDate[0],
            format: 'date',
        }, {
            field: 'order_date',
            name: 'order_date_to',
            type: 'to',
            value: selectedDate[1],
            format: 'date',
        },
    ]);

    const [expandedToolbar, setExpandedToolbar] = useState();
    const { status } = router.query;
    const tab = (status === '' ? 'all' : status) || 'all';
    const isSyncFailed = status === 'sync_failed';
    const primaryKey = isSyncFailed ? 'entity_id' : 'id';

    const dataTrack = [
        { label: t('sellerorder:Waiting_for_Pickup'), value: 'waiting_for_pickup' },
        { label: t('sellerorder:Courier_Pickup'), value: 'courier_pickup' },
        { label: t('sellerorder:Customer_Received'), value: 'customer_received' },
    ];

    // methods
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };
    const fetchRows = () => {
        const variables = {
            pageSize: rowsPerPage,
            currentPage: page,
            filter: [...filters, (!isSyncFailed && {
                field: 'status',
                name: 'status',
                type: 'eq',
                format: 'tab',
                value: status,
            })]
                .filter((e) => !isEmpty(e.value))
                .reduce((accumulator, currentValue) => {
                    accumulator[currentValue.field] = {
                        ...accumulator[currentValue.field],
                        [typeof currentValue.type === 'object'
                            ? currentValue.type[indexType[currentValue.name]]
                            : currentValue.type]: currentValue.value,
                    };
                    return accumulator;
                }, {}),
            search,
        };
        if (isSyncFailed) {
            variables.filter = {
                ...variables.filter,
                sync_status: { eq: 'failed' },
                table_source: { eq: 'sales_shipment' },
            };
        }
        if (getVariables) {
            getVariables(variables);
        }
        getRows({ variables });
        setIsCheckedAllRows(false);
        onReloadAllProductCount();
    };

    const handleChangeCheckboxAllRows = (checked) => {
        const useRows = isSyncFailed ? rowsSyncFailed : rows;
        const newCheckedRows = checked ? useRows.reduce((accumulator, currentValue) => {
            const i = accumulator.findIndex((checkedRow) => checkedRow[primaryKey] === currentValue[primaryKey]);
            if (checked && i < 0) {
                accumulator.push(currentValue);
            }
            return accumulator;
        }, checkedRows) : [];
        setCheckedRows(newCheckedRows);
        setIsCheckedAllRows(checked);
        handleChecked(newCheckedRows);
    };

    const isPrintMpLabel = (channel) => {
        const isAllAllowedPrintMpLabel = (!channel ? checkedRows : channel)
            ?.map((item) => allowedMpPrintLabel.some((v) => (item?.channel_code || item)?.indexOf(v) >= 0))
            ?.every((res) => res);
        return isAllAllowedPrintMpLabel;
    };

    // effects
    useEffect(() => {
        setPage(1);
        fetchRows();
    }, [rowsPerPage, debouncedSearch]);

    useEffect(() => {
        setPage(1);
        fetchRows();
        setIsCheckedAllRows(false);
    }, [filters, tab]);

    useEffect(() => {
        if (getRows) {
            fetchRows();
        }
    }, [page]);

    useEffect(() => {
        if (doRefetch !== null) {
            fetchRows();
            setDoRefetch(null);
        }
    }, [doRefetch]);

    useEffect(() => {
        if (defaultChecked.length) {
            setCheckedRows(defaultChecked);
        }
    }, [defaultChecked]);

    useEffect(() => {
        setCheckedRows([]);
        setIsCheckedAllRows(false);
        handleChecked([]);
    }, [router]);

    useEffect(() => {
        const useRows = isSyncFailed ? rowsSyncFailed : rows;
        if (useRows.length && useRows.every((row) => checkedRows.map((check) => check[primaryKey])?.includes(row[primaryKey]))) {
            setIsCheckedAllRows(true);
        } else {
            setIsCheckedAllRows(false);
        }
    }, [rows, rowsSyncFailed]);

    const renderAction = () => {
        return (
            <Grid container spacing={2} className={classes.checkAllContainer}>
                {showCheckbox && !!count ? (
                    <Grid item xs={12} sm="auto">
                        <FormControlLabel
                            control={(
                                <Checkbox
                                    className={classes.checkbox}
                                    label={`${checkedRows.length} ${t('common:Selected')}`}
                                    checked={isCheckedAllRows}
                                    onChange={(e) => handleChangeCheckboxAllRows(e.target.checked)}
                                    disabled={!count}
                                />
                            )}
                            label={checkedRows.length ? `${checkedRows.length} ${t('common:Order_Selected')}` : t('common:Select_All')}
                            className={classes.controlLabel}
                        />
                    </Grid>
                ) : <Grid item xs={12} sm="auto" style={{ paddingBottom: 20 }} />}
                {actions?.length && checkedRows.length && count
                    ? (actions.map((act) => (
                        <Grid key={act.label} item>
                            <Button
                                className={clsx(classes.btnAction, 'check')}
                                onClick={() => act.onClick(checkedRows)}
                                disabled={!checkedRows.length}
                            >
                                {act.label}
                            </Button>
                        </Grid>
                    ))
                    )
                    : null}
            </Grid>
        );
    };

    const renderTableBody = () => {
        const handleChangeCheckboxRow = (checked, row) => {
            const i = checkedRows.findIndex((checkedRow) => checkedRow[primaryKey] === row[primaryKey]);
            if (singleSelection) {
                setCheckedRows([row]);
                handleChecked([row]);
            } else if (checked && i < 0) {
                setCheckedRows([...checkedRows, row]);
                handleChecked([...checkedRows, row]);
            } else if (!checked && i >= 0) {
                setCheckedRows(checkedRows.filter((checkedRow) => checkedRow[primaryKey] != row[primaryKey]));
                handleChecked(checkedRows.filter((checkedRow) => checkedRow[primaryKey] != row[primaryKey]));
            }
        };
        return (rows.map((row, rowIndex) => {
            return (
                <Paper
                    key={rowIndex}
                    className={classes.rowPaper}
                >
                    <Link
                        href={`/seller/order/orderlist/detail/${row.id}`}
                        key={row.id}
                        style={{
                            cursor: handleClickRow ? 'pointer' : 'default',
                        }}
                    >
                        <a>
                            <div className={classes.rowHead}>
                                <div className={clsx(classes.head, 'block')}>
                                    {showCheckbox && (
                                        <Checkbox
                                            className={clsx(classes.checkbox, 'sub')}
                                            checked={!!checkedRows.find((checkedRow) => checkedRow[primaryKey] === row[primaryKey])}
                                            onChange={(e) => handleChangeCheckboxRow(e.target.checked, row)}
                                            disabled={!checkedRows.find((checkedRow) => checkedRow[primaryKey] === row[primaryKey])
                                                && row.disableCheck}
                                            onClick={(e) => e.stopPropagation()}
                                        />
                                    )}
                                    {row.header
                                        ? getComponentOrString(row.header)
                                        : null}
                                </div>
                                {!!row.acceptance_deadline
                                    && (
                                        <div className={clsx(classes.head, 'end')}>
                                            {t('sellerorder:Acceptance_Deadline')}
                                            <div className={classes.divider} />
                                            <ScheduleIcon className="icon" />
                                            <div className={classes.divider} />
                                            {row.acceptance_deadline}
                                        </div>
                                    )}
                            </div>

                            <div
                                spacing={3}
                                className={classes.rowBody}
                            >
                                {columns.map((column, columnIndex) => (
                                    <div key={columnIndex} className={clsx(column.hidden && 'hide', classes.alignTop, 'box')}>
                                        {column.headerName
                                            ? (
                                                <div className="title">{getComponentOrString(column.headerName)}</div>
                                            )
                                            : null}
                                        {getComponentOrString(row[column.field]) || '-'}
                                    </div>
                                ))}
                                {!!rowActions?.length
                                    && (
                                        <div className={clsx(classes.alignTop)}>
                                            {renderAction(row)}
                                        </div>
                                    )}
                            </div>
                            {row.footer
                                ? <div className={classes.rowFooter}>{getComponentOrString(row.footer)}</div>
                                : null}
                        </a>
                    </Link>
                    {(!!row?.tracks?.track_number || row?.status?.code !== 'canceled') && (
                        <>
                            <Divider className={classes.dividerLine} />
                            <div className={classes.rowFooter}>
                                <div className={classes.printLabel}>
                                    <Button
                                        className={classes.buttonPrint}
                                        startIcon={<img src="/assets/img/box.png" alt="box" />}
                                        onClick={() => {
                                            if (isPrintMpLabel([row.channel_code]) && isMultichannel) {
                                                setSelectedPrintLabel(row.id);
                                                setOpenConfirmation(row.print_pack_counter ? 'single-retry' : 'single');
                                            } else {
                                                window.open(`/seller/order/orderlist/printlabel/${row.id}`);
                                            }
                                        }}
                                    >
                                        
                                        <span>{t('sellerorder:Print_Label')}</span>
                                    </Button>
                                    <Divider className={classes.dividerLineVertical} orientation="vertical" />
                                    <span className={classes.counterPrint}>
                                        {row.print_pack_counter === 0
                                            ? t('sellerorder:Never_Been_Printed')
                                            : `${t('sellerorder:Printed_count_times', { count: row.print_pack_counter })}`}
                                    </span>
                                </div>
                            </div>
                        </>
                    )}
                </Paper>
            );
        })
        );
    };

    const renderMassAction = () => {
        return (
            !loading && (countSyncFailed && checkedRows.length && aclEdit
                ? (
                    <div className={classes.massAction}>
                        <Grid container spacing={3}>
                            <Grid item>
                                {checkedRows.length ? `${checkedRows.length} ${t('sellerorder:Order_Selected')}` : null}
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={() => handleRetry(checkedRows)}
                                    className={classes.massActionButton}
                                >
                                    {t('sellerorder:Resync')}
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                ) : null
            )
        );
    };

    const renderTableHeader = () => {
        const setSortByField = (field) => {
            setSorts(
                sorts.map((sort) => ({
                    ...sort,
                    ...(sort.field === field && { value: sort.value === 'ASC' ? 'DESC' : 'ASC' }),
                    ...(sort.field != field && { value: undefined }),
                })),
            );
        };
        const getSortValue = (field) => {
            const sort = sorts.find((e) => e.field === field);
            return sort && sort.value;
        };
        const getArrowClass = (field) => (getSortValue(field) === 'ASC' ? classes.arrowDown : classes.arrowUp);
        return (
            <TableHead>
                <TableRow className={classes.tableHead}>
                    {aclEdit
                    && (
                        <TableCell padding="checkbox">
                            <Checkbox
                                className={classes.checkbox}
                                checked={isCheckedAllRows}
                                onChange={(e) => handleChangeCheckboxAllRows(e.target.checked)}
                            />
                        </TableCell>
                    )}
                    {columnsSyncFailed.map((column, columnIndex) => (
                        <TableCell
                            key={columnIndex}
                            className={clsx(column.hidden && 'hide')}
                        >
                            {!column.sortable && getComponentOrString(column.headerName)}
                            {column.sortable && (
                                <Button
                                    onClick={() => setSortByField(column.field)}
                                    className={classes.sortButon}
                                    buttonType="link"
                                    endIcon={
                                        getSortValue(column.field) ? (
                                            <ArrowRightAltIcon className={getArrowClass(column.field)} />
                                        ) : (
                                            <ImportExportIcon style={{ opacity: 0.3 }} />
                                        )
                                    }
                                >
                                    {column.headerName}
                                </Button>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    };

    const renderTableBodySyncFailed = () => {
        const handleChangeCheckboxRow = (checked, row) => {
            const i = checkedRows.findIndex((checkedRow) => checkedRow[primaryKey] === row[primaryKey]);
            if (checked && i < 0) {
                if (checkedRows.length === rows.length - 1) {
                    setIsCheckedAllRows(true);
                }
                setCheckedRows([...checkedRows, row]);
            } else {
                if (checkedRows.length !== rows.length - 1) {
                    setIsCheckedAllRows(false);
                }
                setCheckedRows(checkedRows.filter((checkedRow) => checkedRow[primaryKey] != row[primaryKey]));
            }
        };
        return (
            <TableBody>
                {rowsSyncFailed.map((row, rowIndex) => (
                    <TableRow
                        key={rowIndex}
                        className={clsx(classes.tableRow, !!row.variants?.length && 'noBorderBottom', rowIndex % 2 && 'gray')}
                    >
                        {aclEdit
                        && (
                            <TableCell padding="checkbox" className={classes.alignTop}>
                                <Checkbox
                                    className={classes.checkbox}
                                    checked={!!checkedRows.find((checkedRow) => checkedRow[primaryKey] === row[primaryKey])}
                                    onChange={(e) => handleChangeCheckboxRow(e.target.checked, row)}
                                    disabled={!checkedRows.find((checkedRow) => checkedRow[primaryKey] === row[primaryKey]) && row.disableCheck}
                                />
                            </TableCell>
                        )}
                        {columnsSyncFailed.map((column, columnIndex) => (
                            <TableCell key={columnIndex} className={clsx(column.hidden && 'hide', classes.alignTop)}>
                                {getComponentOrString(row[column.field]) || '-'}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        );
    };

    const renderTableFooter = () => {
        return (
            <>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            className={classes.tablePagination}
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            labelRowsPerPage={t('common:View')}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            labelDisplayedRows={() => { }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    const contentProps = {
        ...props,
        filters,
        setFilters,
        search,
        setSearch,
        fetchRows,
        setExpandedToolbar,
        expandedToolbar,
        tab,
        selectedDate,
        setSelectedDate,
        dataTrack,
        setCheckedRows,
        setIsCheckedAllRows,
    };

    return (
        <>
            {useTabs && <TabsHeader {...contentProps} />}
            {isSyncFailed
                ? (
                    <div className={classes.mainTable}>
                        {renderMassAction()}
                        <Table size="small" stickyHeader>
                            {renderTableHeader()}
                            {loading ? (
                                null
                            ) : rowsSyncFailed.length ? (
                                renderTableBodySyncFailed()
                            ) : (
                                null
                            )}
                        </Table>
                        {loading || !rowsSyncFailed.length
                            ? (
                                <Table size="small">
                                    <TableBody>
                                        <TableRow style={{ height: 50 }}>
                                            <TableCell>
                                                {loading ? (
                                                    <div className={classes.loading}>Loading . . .</div>
                                                ) : !rowsSyncFailed.length ? (
                                                    <div className={classes.loading}>{t('common:No_records_to_display')}</div>
                                                ) : (
                                                    null
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            )
                            : null}
                    </div>
                )
                : (
                    <div>
                        {renderAction()}
                        {loading ? (
                            <span className={classes.loading}>Loading . . .</span>
                        ) : rows.length ? (
                            renderTableBody()
                        ) : (
                            <span className={classes.loading}>{t('common:No_records_to_display')}</span>
                        )}
                    </div>
                )}
            <TableContainer component={Paper} className={clsx(classes.tableContainer, 'footer')}>
                <Table size="small">{!hideFooter && !!(isSyncFailed ? countSyncFailed : count) && renderTableFooter()}</Table>
            </TableContainer>
            <div style={{ height: 20 }} />
        </>
    );
};

export default CustomTable;
