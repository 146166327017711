import { gql } from '@apollo/client';

export const getSellerOrders = gql`
query getSellerOrders(
  $search: String
  $filter: SellerFilterInput
  $sort: SellerSortInput
  $pageSize: Int
  $currentPage: Int
) {
  getSellerOrders(
    search: $search
    filter: $filter
    sort: $sort
    pageSize: $pageSize
    currentPage: $currentPage
  ) {
    items {
      acceptance_deadline
      channel_payment_method
      channel {
        logo
        name
      }
      channel_code
      customer {
        email
        name
      }
      entity_id
      expedition {
        provider
        service
      }
      grand_total
      item_preview {
        items {
          discount_amount
          image
          name
          price
          qty
          remark
          sku
          item_bundle {
            image
            name
            parent_item_id
            vendor_sku
          }
        }
        qty_more
      }
      items {
        discount_amount
        image
        name
        price
        qty
        remark
        sku
      }
      order_date
      order_number
      print_pack_counter
      remark
      shipping_address {
        city
        country_name
        postcode
        region
        street
        telephone
      }
      shipping_amount
      status {
        code
        label
      }
      total_item_count
      tracks {
        carrier_code
        created_at
        description
        status {
          code
          label
        }
        title
        track_number
      }
      location {
        loc_id
        loc_name
      }
      is_indent
      marketplace_platform {
        platform_logo
      }
    }
    page_info {
      current_page
      page_size
      total_pages
    }
    total_count
  }
}
`;

export const getSellerOrder = gql`
query getSellerOrder($id: Int!) {
  getSellerOrder(id: $id) {
    channel {
      logo
      name
    }
    channel_code
    channel_payment_method
    customer {
      email
      name
    }
    coupon_code
    only_free_shipping_coupon_code
    discount
    entity_id
    expedition {
      provider
      service
      pickup_start_time
      pickup_end_time
      shipping_code
    }
    extra_fee_amount
    grand_total
    history {
      comment
      created_at
      entity_id
      status {
        code
        label
      }
    }
    item_preview {
      items {
        discount_amount
        image
        name
        price
        qty
        remark
        sku
        subtotal
      }
      qty_more
    }
    items {
      discount_amount
      image
      name
      price
      qty
      remark
      sku
      subtotal
      variants {
        label
        value
      }
      vendor_sku
      parent_item_id
      entity_id
    }
    order_date
    order_number
    remark
    shipping_address {
      city
      country_name
      postcode
      region
      street
      telephone
    }
    shipping_amount
    status {
      code
      label
    }
    subtotal
    total_item_count
    tracks {
      carrier_code
      created_at
      description
      status {
        code
        label
      }
      title
      track_number
    }
    is_indent
    marketplace_platform {
      marketplace_code
      platform_code
      platform_logo
      platform_name
    }
  }
}
`;

export const printSellerOrderLabel = gql`
    query printSellerOrderLabel($id: [Int!]) {
        printSellerOrderLabel(id: $id) {
            customer {
                email
                name
            }
            expedition {
                provider
                service
            }
            items {
                discount_amount
                image
                name
                price
                qty
                remark
                sku
                subtotal
                vendor_sku
                entity_id
                parent_item_id
            }
            order_number
            shipping_address {
                city
                country_name
                postcode
                region
                street
                telephone
            }
            shipping_amount
            shipping_method_logo_url
            store {
                category
                channel_store_id
                city
                country
                country_name
                hash_key
                id
                name
                partner_id
                post_code
                region
                street
                telephone
            }
            store_logo_url
            track_number
            track_number_barcode_url
            weight
        }
    }
`;

export const printSellerChannelOrderLabel = gql`
query printSellerChannelOrderLabel($ids: [Int!]) {
  printSellerChannelOrderLabel(ids: $ids)
}
`;

export const retryPrintSellerChannelOrderLabel = gql`
query retryPrintSellerChannelOrderLabel($id: Int!) {
  retryPrintSellerChannelOrderLabel(id: $id)
}
`;

export const printSellerInvoice = gql`
    query printSellerInvoice($id: [Int!]!) {
        printSellerInvoice(id: $id) {
            channel_order_date
            coupon_code
            only_free_shipping_coupon_code
            discount
            customer {
                email
                name
            }
            expedition {
                provider
                service
            }
            grand_total
            items {
                price
                name
                qty
                row_total
                entity_id
                parent_item_id
            }
            order_number
            seller
            shipping_address {
                city
                country_name
                postcode
                region
                street
                telephone
            }
            store_logo_url
            subtotal
            total_items
            total_shipping_cost
            total_weight
            updated_at
        }
    }
`;

export const sellerCanceled = gql`
mutation sellerCanceled($id: [Int!], $reason: String!) {
    sellerCanceled(id: $id, reason: $reason)
   }
`;

export const sellerConfirm = gql`
mutation sellerConfirm($id: [Int!]) {
    sellerConfirm(id: $id)
   }
`;

export const sellerBookCourier = gql`
mutation sellerBookCourier($id: [Int!], $pickup_time: String) {
    sellerBookCourier(id: $id, pickup_time: $pickup_time)
  }
`;

export const sellerReadyforShip = gql`
mutation sellerReadyforShip($id: [Int!]) {
    sellerReadyforShip(id: $id)
   }
`;

export const sellerOrderDelivered = gql`
mutation sellerOrderDelivered($id:[Int!]) {
    sellerOrderDelivered(id: $id)
}
`;

export const getGenerateAwbMethod = gql`
query getGenerateAwbMethod($id: Int!) {
    getGenerateAwbMethod(id: $id)
}
`;

export const sellerOrderInDelivery = gql`
mutation sellerOrderInDelivery($id: [Int!], $awb_number: String) {
    sellerOrderInDelivery(id: $id, awb_number: $awb_number)
}
`;

export const downloadSellerOrderReport = gql`  
    mutation downloadSellerOrderReport (
            $date_from: String!,
            $date_to: String!
    ) {
        downloadSellerOrderReport(
            input: {
                date_from: $date_from,
                date_to: $date_to
            }
        )
    }
`;

export const getSellerStoreOptions = gql`
{
    getSellerStoreOptions {
      label
      value
    }
  }
`;

export const sellerCancelDelivery = gql`
    mutation sellerCancelDelivery($id: [Int!]) {
        sellerCancelDelivery(id: $id)
    }
`;

export const getPickupTimeslots = gql`
query getPickupTimeslots($shipping_code: String!) {
    getPickupTimeslots(shipping_code: $shipping_code) {
        label
        value
    }
  }
`;

export const getSellerOrdersCount = gql`
    query getSellerOrders($filter: SellerFilterInput) {
        getSellerOrders(filter: $filter) {
            total_count
        }
    }
`;

export const getSellerOrderStatus = gql`
query getSellerOrderStatus($status: String) {
    getSellerOrderStatus(status: $status) {
      label
      value
    }
  }
`;

export const getSellerOrderMeta = gql`
query getSellerOrderMeta($filter: SellerFilterInput) {
  getSellerOrderMeta(filter: $filter) {
    order {
      all_orders {
        name
        value
      }
      canceled {
        name
        value
      }
      complete {
        name
        value
      }
      new_order {
        name
        value
      }
      packed {
        name
        value
      }
      shipped {
        name
        value
      }
    }
  }
}
`;

export const getCancelReasonsByChannel = gql`
query getCancelReasonsByChannel($channel_code: String!) {
  getCancelReasonsByChannel(channel_code: $channel_code) {
    input_type
    options {
      label
      value
    }
  }
}
`;

export const csStores = gql`
query csStores(
  $search: String
  $filter: QueryCsStoresFilterWhereConditions
  $hasChannel: QueryCsStoresHasChannelWhereHasConditions
  $sort: [QueryCsStoresSortOrderByClause!]
  $first: Int!
  $page: Int
) {
  csStores(
    search: $search
    filter: $filter
    hasChannel: $hasChannel
    sort: $sort
    first: $first
    page: $page
  ) {
    data {
      store_id
      store_code
      store_name
      status
      status_label
      channel {
        channel_logo
        channel_code
      }
      brands {
        store_brand_id
      }
    }
  }
}
`;

export const getSellerOrderSyncStatusList = gql`
query getSellerOrderSyncStatusList(
  $search: String
  $filter: OrderSyncStatusFilterInput
  $sort: OrderSyncStatusSortInput
  $pageSize: Int
  $currentPage: Int
) {
  getSellerOrderSyncStatusList(
    search: $search
    filter: $filter
    sort: $sort
    pageSize: $pageSize
    currentPage: $currentPage
  ) {
    items {
      entity_id
      order_number
      order_date
      status {
        label
      }
      channel {
        logo
        name
      }
      channel_code
      sync_status {
        label
      }
      table_source
    }
    page_info {
      current_page
      page_size
      total_pages
    }
    total_count
  }
}
`;

export const getSellerOrderSyncStatusListCount = gql`
query getSellerOrderSyncStatusListCount(
  $filter: OrderSyncStatusFilterInput
) {
  getSellerOrderSyncStatusList(
    filter: $filter
    pageSize: 1
    currentPage: 1
  ) {
    total_count
  }
}
`;

export const retrySellerOrderSync = gql`
    mutation retrySellerOrderSync($input: [OrderToSync!]) {
        retrySellerOrderSync(input: $input)
    }
`;

export const getActivity = gql`
    query getActivity($code: String!) {
        getActivity(code: $code, by_session: true) {
            activity_id
            activity_code
            run_status
            data_total
            data_processed
            started_at
            snapshot_at
            finished_at
            run_by
            run_type
            attachment
            error_message
        }
    }
`;

export const getExportHistory = gql`
query getExportHistory(
  $filter: ExportHistoryFilterInput
  $sort: ExportHistorySortInput
  $pageSize: Int
  $currentPage: Int
) {
  getExportHistory(
    filter: $filter
    pageSize: $pageSize
    currentPage: $currentPage
    sort: $sort
  ) {
    items {
      data_count
      entity_id
      export_at
      export_type
      status
      url
      user
    }
    page_info {
      current_page
      page_size
      total_pages
    }
    total_count
  }
}
`;

export const getSellerChannelList = gql`
query getSellerChannelList($channel_code: String!) {
  getSellerChannelList(
    filter: { channel_code: { eq: $channel_code } }
    pageSize: 1
    currentPage: 1
  ) {
    items {
      channel_name
      status
    }
  }
}
`;

export const getMarketplacePlatforms = gql`
query getMarketplacePlatforms(
  $pageSize: Int
  $currentPage: Int
  $filter: MarketplacePlatformFilterInput
  $sort: MarketplacePlatformSortInput
) {
  getMarketplacePlatforms(
    pageSize: $pageSize
    currentPage: $currentPage
    filter: $filter
    sort: $sort
  ) {
    items {
      marketplace_code
      platform_code
      platform_logo
      platform_name
    }
    page_info {
      current_page
      page_size
      total_pages
    }
    total_count
  }
}
`;

export default {
    getSellerOrders,
    getSellerOrder,
    printSellerOrderLabel,
    printSellerChannelOrderLabel,
    printSellerInvoice,
    sellerCanceled,
    sellerConfirm,
    sellerBookCourier,
    sellerReadyforShip,
    sellerOrderDelivered,
    getGenerateAwbMethod,
    sellerOrderInDelivery,
    downloadSellerOrderReport,
    getSellerStoreOptions,
    sellerCancelDelivery,
    getPickupTimeslots,
    getSellerOrdersCount,
    getSellerOrderStatus,
    getSellerOrderMeta,
    getCancelReasonsByChannel,
    csStores,
    getSellerOrderSyncStatusList,
    getSellerOrderSyncStatusListCount,
    retrySellerOrderSync,
    getActivity,
    getExportHistory,
    getSellerChannelList,
    getMarketplacePlatforms,
};
